import axios from "axios";

// const MANAGEMENT_CONSOLE_BASE_URL = "http://10.10.72.16:6700";
// const USER_BASE_URL = "http://10.10.72.16:6701";
// const CMS_BASE_URL = "http://10.10.72.16:6705";

const MANAGEMENT_CONSOLE_BASE_URL = "https://api-stage.synnexb2b.com.au/qa";
const USER_BASE_URL = "https://api-stage.synnexb2b.com.au/qa";
const CMS_BASE_URL = "https://api-stage.synnexb2b.com.au/qa";

const api = axios.create({
  baseURL: MANAGEMENT_CONSOLE_BASE_URL,
});

const userApi = axios.create({
  baseURL: USER_BASE_URL,
});

// Orchid Core CMS Base URL
const cmsApi = axios.create({
  baseURL: CMS_BASE_URL,
});

export { api, userApi, cmsApi };
